import "../styles/profile.css";
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { getUserBalance, claimRewards, getStakingRewards, getUserTransactions, getMyMarketNFTs } from "../server/Wallet";
import { getUser, updateUsername } from "../server/Profile";

import key from "../assets/Cybros_Token.webp"

export default function Profile(props) {
  const profileImageRef = useRef();
  const backToTop = useRef();
  const navigate = useNavigate();
  const usernameInput = useRef();

  const [balance, setBalance] = useState(0);
  const [claimableBalance, setClaimableBalance] = useState(0);
  const [userHistory, setUserHistory] = useState([]);
  const [userPurchases, setuserPurchases] = useState([]);
  const [username, setUsername] = useState("");
  const [points, setPoints] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [editProfile, setEditProfile] = useState(false);

  async function getBalance() {
    try {
      const balance = await getUserBalance(props.wallet);
      setBalance(balance)
    } catch (err) {
      console.log(err)
    }
  }

  async function getStakingInformation() {
    const stakingRewards = await getStakingRewards(props.wallet);
    setClaimableBalance(Number(stakingRewards._rewards._hex) / Math.pow(10, 18));
  }

  async function getUsersHistory() {
    const history = await getUserTransactions()
    setUserHistory(history.data.result)
  }

  async function grabUserInfo() {
    const user = await getUser(props.wallet);
    setUsername(user?.username);
    setPoints(user?.points);
    setProfileImage(user?.profileImage);
  }

  async function updateUserData() {
    const userUpdate = updateUsername(props.wallet, usernameInput.current.value);
    setUsername(usernameInput.current.value);
    alert("Username updated!")
  }

  async function fetchUserMarketNFTs() {
    const userNFTs = await getMyMarketNFTs();
    console.log(userNFTs)
    setuserPurchases(userNFTs)
  }

  useEffect(() => {
    if (props.wallet === "") return navigate('/');
    getBalance()
    getStakingInformation()
    getUsersHistory()
    grabUserInfo()
    fetchUserMarketNFTs()
  }, [])

  return (
    <div>
      <div className="Profile_Header">
        <div>
          <div className="Profile_Title">Profile</div>
          <div className="Profile_Description">A simple dashboard for you to conveniently view your balance.</div>
        </div>

        <div>
          <img alt="" className="Profile_Key" height={350} src={key}></img>
        </div>
      </div>

      <div className="User_Section">
        <ConfirmMessage />

        <div style={{ display: "grid", alignItems: "center", padding: "1rem" }}>
          <div className="User_Profile_Section">

            <div>
              <div style={{ textAlign: "center", padding: ".5rem", fontSize: "2rem" }} className="User_Wallet_Title">{username ? username : "Username not set"}</div>
              <img alt="" className="Profile_Image" height={75} src={profileImage ? profileImage : "https://pbs.twimg.com/profile_images/1687522774863007757/5a98rSBl_400x400.jpg"}></img>
              <button
                style={{ padding: ".3rem .3rem", fontSize: ".5rem" }}
                className="Claim_Button"
                onClick={() => window.open(`https://opensea.io/${props.wallet}`)}
              >Opensea
              </button>
            </div>

            <button className="Claim_Button" onClick={() => setEditProfile(!editProfile)}>Edit Profile</button>
          </div>
        </div>

        <div className="User_Wallet">
          <div className="User_Balance">
            <div className="User_Wallet_Title">You Own</div>
            <div className="User_Wallet_Balance">
              {Number(balance / Math.pow(10, 18)).toFixed(4)} CybrVerse
            </div>
          </div>

          <div className="User_Balance_2">
            <div className="User_Wallet_Title">CybrVerse To Claim</div>
            <div className="User_Wallet_Balance">
              {claimableBalance?.toFixed(4)} CybrVerse
            </div>
          </div>

          <div className="User_Balance_3">
            <div className="User_Wallet_Balance">
              <button onClick={() => claimRewards()} className="Claim_Button">Claim CyberVerse</button>
            </div>
          </div>
        </div>
      </div>


      <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "2rem" }}>
        <div style={{ background: "#111", padding: "1rem", fontSize: "1.5rem" }}>Your Transactions</div>
        <div style={{ display: "grid", overflowY: "scroll", maxHeight: "50dvh" }}>
          {userHistory && userHistory.map(history =>
            <>
              <div style={{ padding: "1rem 1rem", background: "#222", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                  <div>
                    <div style={{ background: "#222" }}>
                      {new Date(history.timeStamp * 1000).toISOString()})
                    </div>
                    <div className="User_Wallet_Title">From</div>
                    <div style={{ fontSize: "1.75rem" }} className="User_Wallet_Balance">
                      {history.from.slice(0, 12)}...
                    </div>
                  </div>

                  <div>
                    <div className="User_Wallet_Title">To</div>
                    <div style={{ fontSize: "1.75rem" }} className="User_Wallet_Balance">
                      {history.to.slice(0, 12)}...
                    </div>
                  </div>

                  {history.from === String(0xD51E16aA3F184BB6827BFCdBf156ec30D76dE68d).toUpperCase() && (
                    <div>Official Marketplace Transaction</div>
                  )}
                </div>

                <div>
                  <button style={{ fontSize: ".75rem" }} className="Claim_Button">View Transaction</button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div style={{ marginTop: '1rem', fontSize: "1.5rem", textAlign: "center" }} className="User_Section">
        Discord linking coming soon with rewards.
      </div>
    </div >
  )

  function ConfirmMessage() {

    return (
      <>
        {editProfile === true && (
          <div ref={backToTop} style={{
            position: "absolute",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            minWidth: "100%",
            minHeight: "100%",
            // background: "#222",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            overflow: "auto"
          }}>
            <div ref={backToTop}></div>
            <div>
              <div style={{
                textAlign: "center",
                position: "absolute",
                padding: 15,
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                maxWidth: 350,
                maxHeight: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                background: "black",
                overflow: "hidden",
                borderRadius: "1rem 1rem",
                border: "1px white solid",
                boxShadow: "0px 0px 80dvh white"
              }}>
                <div style={{ fontSize: "1.5rem", padding: "1rem" }}>Edit Profile</div>
                <div style={{ fontSize: "1.5rem", borderRadius: "1rem", padding: "1rem" }}>
                  <input
                    type="text"
                    ref={usernameInput}
                    placeholder="username"
                    style={{
                      display: "grid",
                      padding: ".5rem",
                      fontSize: "1.5rem",
                      borderRadius: "1rem",
                      fontFamily: "Bebas Neue, sans-serif"
                    }}
                  >
                  </input>

                  <button
                    onClick={() => updateUserData()}
                    style={{ fontSize: "1.25rem", marginRight: "auto", marginLeft: "auto" }}
                    className="connect_button">Save</button>
                </div>

                <div>
                  <div>
                    {/* <button onClick={() => profileImageRef.current.click()}>
                      Upload Profile Image
                    </button> */}

                    <div style={{ marginTop: "1rem", fontSize: "1.5rem", background: "#555", borderRadius: "1rem" }}>
                      <div>
                        User Wallet
                      </div>
                      <div>{props.wallet.slice(0, 12)}...</div>
                    </div>

                    <button onClick={() => setEditProfile(!editProfile)} style={{ marginLeft: "auto", marginRight: "auto" }} className="connect_button">close</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

}
