import "../styles/navbar.css";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectWallet, checkConnected } from "../server/Wallet";
import { Link } from "react-router-dom";

import rocket from "../assets/rocket_launch.svg";

export default function Navbar(props) {
  const navigate = useNavigate()
  const [wallet, setWallet] = useState("");
  const [menu, setMenu] = useState(false);

  async function connectWallet() {
    const wallet = await ConnectWallet();
    console.log(wallet);
    setWallet(wallet);
    props.setWalletAddress(wallet);
  }

  return (
    <>
      <div style={{ padding: "10px" }}></div>
      <div className="navbar">
        <Link style={{ textDecoration: "none", color: "white" }} to={"/"}>
          <div className="navbar_title">MyCozyPlace</div>
        </Link>

        <div
          className="Navbar_Buttons_Section"
        >
          {!wallet && (
            <button onClick={() => connectWallet()} className="connect_button">
              Connect{" "}
              <img
                alt=""
                style={{ marginLeft: "10px" }}
                height={25}
                src={rocket}
              ></img>
            </button>
          )}

          {wallet && (
            <>
              <Link style={{ textDecoration: "none" }} to={"/stake"}>
                <button className="connect_button">Stake</button>
              </Link>

              <Link style={{ textDecoration: "none" }} to={"/marketplace"}>
                <button className="connect_button">Marketplace</button>
              </Link>

              <Link style={{ textDecoration: "none" }} to={"/profile"}>
                <button className="connect_button">
                  {wallet.toString().slice(0, 6)}...
                </button>
              </Link>
            </>
          )}
        </div>

        <div className="Navbar_Buttons_Section_Mobile">
          <button onClick={() => setMenu(!menu)} className="connect_button">MENU</button>
        </div>

        {menu === true && (
          <div className="Navbar_Menu">
            {!wallet && (
              <button onClick={() => connectWallet()} className="connect_button">
                Connect{" "}
                <img
                  alt=""
                  style={{ marginLeft: "10px" }}
                  height={25}
                  src={rocket}
                ></img>
              </button>
            )}

            {wallet && (
              <>
                <Link style={{ textDecoration: "none" }} to={"/stake"}>
                  <button className="connect_button">Stake</button>
                </Link>

                <Link style={{ textDecoration: "none" }} to={"/marketplace"}>
                  <button className="connect_button">Marketplace</button>
                </Link>

                <Link style={{ textDecoration: "none" }} to={"/profile"}>
                  <button className="connect_button">
                    {wallet.toString().slice(0, 6)}...
                  </button>
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
