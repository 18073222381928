import "../styles/marketplace.css";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getMarketNFTs, getBuyMarketNFT } from "../server/Wallet"

export default function Marketplace(props) {
  const navigate = useNavigate()
  const backToTop = useRef()
  const [marketplaceNFTs, setMarketplaceNFTs] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedNFT, setSelectedNFT] = useState([]);
  const [pendingTransaction, setPendingTransaction] = useState(false);
  const [status, setStatus] = useState("");
  const [toUser, setToUser] = useState("");
  const [fromUser, setFromUser] = useState("");
  const [warningMessage, setWarningMessage] = useState(false);

  async function getMarketData() {
    const data = await getMarketNFTs();

    setMarketplaceNFTs(data);
  }

  async function buyMarketItem(address, tokenId, price, name, image) {
    setPendingTransaction(true);
    const transaction = await getBuyMarketNFT(address, tokenId, price);

    if (transaction.status === 1) {
      getMarketData();
      setPendingTransaction(false);
      setMessage(transaction.transactionHash);
      setStatus("Transaction Successful!");
      setToUser(transaction.to);
      setFromUser(transaction.from);

      let itemAddedEmbed = {
        embeds: [
          {
            type: "rich",
            title: `🏪 Marketplace Buy`,
            description: `Item: **${name}**\nPrice: **${price} $CybrVerse**\nBuyer: **${transaction.from.slice(0, 12)}...**`,
            color: 0xdf4b4b,
            url: `https://mycozy.place`,
            thumbnail: {
              url: image,
              proxy_url: image,
              height: 250,
              width: 250,
            },
          },
        ],
      };

      fetch(
        "https://discord.com/api/webhooks/1141833076436115539/FPcOoop_fAtLPpV79w0nhGvnEp9X5Muo-sybLGbkadn6g60tRA-_mdDjiGH77NzeLaPD",
        {
          method: "POST",
          body: JSON.stringify(itemAddedEmbed),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(() => {
          console.log("Burn successful!");
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (transaction.status === 0) {
      // setSelectedNFT([])
      getMarketData();
      setPendingTransaction(false);
      setMessage(transaction.hash);
      setStatus("Transaction Failed!");
    } else if (transaction === "User Rejected Transaction") {
      setPendingTransaction(false);
    } else if (transaction) {
      setWarningMessage(true);
      setPendingTransaction(false);
      setStatus(transaction);
      setMessage("Failed");
    }
  }

  // function closeModal() {
  //   setMessage("");
  //   setStatus("");
  //   setSelectedNFT([]);
  // }

  async function showNFT(props) {
    await setSelectedNFT(...selectedNFT, [props.data.contract, props.itemId, props.price / Math.pow(10, 18), props.image, props.name])
    backToTop.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (props.wallet === "") return navigate("/")
    getMarketData()
  }, [])

  return (
    <>
      <Message />
      <ConfirmMessage />
      <Title />

      <div className="marketplace_background">
        <LoadingMarketplace />
        <MarketplaceGrid />
      </div>
    </>
  );

  function Title() {

    return (
      <>
        <div style={{ padding: "2rem" }}></div>

        <div className="marketplace_title">CybrVerse Marketplace</div>
        <div className="marketplace_title"
          style={{ fontSize: "1.3rem" }}
        >
          You can stake your CybrosNFT to earn $CybrVerse and purchase NFT's. You will also be able to sell your NFT's for $CybrVerse soon.
        </div>
        <div style={{ padding: "2rem" }}></div>
      </>
    )
  }

  function LoadingMarketplace() {

    return (
      <>
        {marketplaceNFTs.length < 1 && (
          <div style={{ minHeight: "100dvh" }} className="grid_div">
            <div style={{ fontSize: "2rem" }}>Loading Marketplace...</div>
          </div>
        )}
      </>
    )
  }

  function ConfirmMessage() {

    return (
      <>
        {selectedNFT.length > 0 && pendingTransaction === false && (
          <div ref={backToTop} style={{
            position: "absolute",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            minWidth: "100%",
            minHeight: "100%",
            // background: "#222",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            overflow: "auto"
          }}>
            <div ref={backToTop}></div>
            <div>
              <div style={{
                textAlign: "center",
                position: "absolute",
                padding: 15,
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                maxWidth: 350,
                maxHeight: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                background: "black",
                overflow: "hidden",
                borderRadius: "1rem 1rem",
                border: "1px white solid",
                boxShadow: "0px 0px 80dvh white"
              }}>
                <div style={{ background: "transparent", padding: ".5rem", margin: "0px", fontSize: "2rem" }}>Approve Purchase</div>

                <hr style={{ minWidth: "100%" }}></hr>

                <div style={{ display: "flex", alignItems: "center", padding: ".5rem" }}>
                  <img alt="" style={{ marginRight: "auto", marginTop: "auto", marginBottom: "auto", borderRadius: "1rem 1rem" }} height={75} src={selectedNFT[3]}></img>

                  <div style={{ marginLeft: "1rem", marginTop: "auto", marginBottom: "auto" }}>
                    <div style={{ fontSize: "1.3rem", textAlign: "left" }}>{selectedNFT[4]}</div>
                    <div style={{ fontSize: "1.3rem", textAlign: "left" }}>#{selectedNFT[1]}</div>
                  </div>
                </div>

                <div style={{ textAlign: "center", padding: ".5rem" }} className="nft_card_title">
                  <div>Amount</div>
                  {selectedNFT[2]} $CybrVerse
                </div>

                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: ".5rem"
                }}>
                  <button style={{ padding: "1rem", background: "#8080FF" }} onClick={() => buyMarketItem(selectedNFT[0], selectedNFT[1], selectedNFT[2], selectedNFT[4], selectedNFT[3])} className="purchase_button">Confirm</button>
                  <button style={{ padding: "1rem" }} onClick={() => setSelectedNFT([])} className="purchase_button">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {pendingTransaction === true && (
          <div style={{
            position: "absolute",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            // background: "#222",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            overflow: "auto"
          }}>
            <div>
              <div style={{
                textAlign: "center",
                padding: 15,
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                maxWidth: 350,
                maxHeight: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                background: "black",
                overflow: "hidden",
                borderRadius: "1rem 1rem",
                border: "1px white solid",
                boxShadow: "0px 0px 80dvh orange"
              }}>
                <div style={{ background: "transparent", padding: "0px 0px", margin: "0px", fontSize: "2rem" }}>Transaction Pending...</div>

                <hr style={{ minWidth: "100%" }}></hr>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <img alt="" style={{ marginRight: "auto", marginTop: "auto", marginBottom: "auto", borderRadius: "1rem 1rem" }} height={75} src={selectedNFT[3]}></img>

                  <div style={{ marginLeft: "1rem", marginTop: "auto", marginBottom: "auto" }}>
                    <div style={{ fontSize: "1.3rem", textAlign: "left" }}>{selectedNFT[4]}</div>
                    <div style={{ fontSize: "1.3rem", textAlign: "left" }}>#{selectedNFT[1]}</div>
                  </div>
                </div>

                <div style={{ textAlign: "center" }} className="nft_card_title">
                  <div>Amount</div>
                  {selectedNFT[2]} $CybrVerse
                </div>

                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: ".5rem"
                }}>
                  <button disabled={true} style={{ padding: "1rem", background: "#8080FF" }} className="purchase_button">Buying NFT...</button>
                  <button className="purchase_button" onClick={() => window.location.reload()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {status && pendingTransaction === false && warningMessage === false && (
          <div style={{
            position: "absolute",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            // background: "#222",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            overflow: "auto"
          }}>
            <div>
              <div style={{
                textAlign: "center",
                padding: 15,
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                maxWidth: 350,
                maxHeight: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                background: "black",
                overflow: "hidden",
                borderRadius: "1rem 1rem",
                border: "1px white solid",
                boxShadow: "0px 0px 80dvh green"
              }}>
                <div style={{ background: "transparent", padding: "0px 0px", margin: "0px", fontSize: "2rem", cursor: "pointer", textDecoration: "underline" }}>{status}</div>

                <hr style={{ minWidth: "100%" }}></hr>

                <div>From</div>
                <div style={{
                  textAlign: "center"
                }} className="nft_card_title">{fromUser.slice(0, 12)}...</div>

                <div>To</div>
                <div style={{
                  textAlign: "center"
                }} className="nft_card_title">{toUser.slice(0, 12)}...</div>

                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1.5rem"
                }}>
                  <button className="purchase_button" onClick={() => window.location.reload()}>Close</button>

                  <button className="purchase_button" onClick={() => window.open(`https://testnets.opensea.io/assets/mumbai/${selectedNFT[0]}/${selectedNFT[1]}`)}>View Item</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {status && pendingTransaction === false && warningMessage === true && (
          <div style={{
            position: "absolute",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            // background: "#222",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            overflow: "auto"
          }}>
            <div>
              <div style={{
                textAlign: "center",
                padding: 15,
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                maxWidth: 350,
                maxHeight: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                background: "black",
                overflow: "hidden",
                borderRadius: "1rem 1rem",
                border: "1px white solid",
                boxShadow: "0px 0px 80dvh red"
              }}>
                <div style={{ background: "transparent", padding: "0px 0px", margin: "0px", fontSize: "2rem", cursor: "pointer", textDecoration: "underline" }}>{status}</div>

                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1.5rem"
                }}>
                  <button className="purchase_button" onClick={() => window.location.reload()}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  function MarketplaceGrid() {

    return (
      <>
        {marketplaceNFTs.length > 0 && (
          <div className="grid_div">
            <div className="nft_grid_market">
              {marketplaceNFTs && selectedNFT.length === 0 && marketplaceNFTs.map(nfts =>
                <>
                  {nfts?.nftData?.image_url && (
                    <NFTCard
                      image={nfts?.nftData?.image_url}
                      name={nfts?.nftData?.name}
                      tokenID={nfts?.nftData?.identifier}
                      price={nfts.price}
                      seller={nfts.seller}
                      data={nfts.nftData}
                      itemId={nfts.itemId}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  function NFTCard(props) {

    return (
      <div>
        <div className="nft_card">
          <div>
            <img
              alt=""
              src={props?.image ? props.image : "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"}
              className="nft_card_image"
            ></img>
          </div>

          <div className="nft_card_title">
            {props.name}
            <div style={{ textAlign: "left", fontSize: "1.2rem" }}>Token ID: #{props.tokenID}</div>
          </div>

          <div style={{ display: "flex", padding: "1rem", justifyContent: "space-between" }}>
            <div>
              <div>Price</div>
              <span style={{ fontWeight: "bolder", fontSize: "1.15rem" }}>{props.price / Math.pow(10, 18)} $CYBERVERSE</span>
            </div>

            <div>
              <div>Seller</div>
              <span style={{ fontWeight: "bolder", fontSize: "1.15rem" }}>{props?.seller?.slice(0, 6)}</span>
            </div>
          </div>

          <div style={{ display: "flex", paddingLeft: "1rem", paddingRight: "1rem", justifyContent: "space-between", marginBottom: '1rem', fontSize: "1.15rem" }}>
            <div>Contract</div>
            <a style={{ color: "white", cursor: "pointer", textDecoration: "underline" }} onClick={() => window.open(`https://mumbai.polygonscan.com/address/${props?.data?.contract}`)}>{props?.data?.contract?.slice(0, 12)}</a>
          </div>

          <button className="purchase_button" onClick={() => showNFT(props)}>
            Purchase
          </button>
        </div>
      </div>
    );
  }

  function Message() {

    return (
      <>
        {message && (
          <div style={{ background: "#313131", textAlign: "center", marginRight: "1rem", marginLeft: "1rem", fontSize: "1.3rem", padding: ".3rem", borderRadius: "1rem", marginTop: ".3rem" }}>
            {message}
          </div>
        )}
      </>
    )
  }
}
