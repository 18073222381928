import "./styles/background.css";
import "./styles/navbar.css";
import "./styles/home.css";

import { useState } from 'react';
import { Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Test from "./components/Test";
import Marketplace from "./components/Marketplace";
import Profile from "./components/Profile";

function App() {
  const [walletAddress, setWalletAddress] = useState("");

  return (
    <div className="background">
      <Navbar setWalletAddress={(address) => setWalletAddress(address)} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/stake" element={<Test date={new Date().getTime()} wallet={walletAddress} />} />
        <Route path="/marketplace" element={<Marketplace wallet={walletAddress} />} />
        <Route path="/profile" element={<Profile wallet={walletAddress} />} />
      </Routes>
    </div>
  );
}

export default App;
