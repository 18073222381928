import "../styles/home.css";
import "../styles/background.css";
import "../styles/staking.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";

import axios from "axios";

import {
  getWalletNFTs,
  getStakingRewards,
  claimRewards,
  StakeNFT,
  unstakeNFTs,
  stakeAllNFT,
  unstakeNFT,
  getTotalStaked
} from "../server/Wallet";
// import { ethers } from "ethers";

export default function Test(props) {
  const navigate = useNavigate();
  const [nfts, setNfts] = useState([]);
  const [stakingRewards, setStakingRewards] = useState(0);
  const [tokensStaked, setTokenStaked] = useState(0);
  const [tokensStakedData, setTokensStakedData] = useState([]);
  const [unstakedNFTsArray, setUnstakedNFTsArray] = useState([]);
  const [receipt, setReceipt] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0)

  async function runStake(token, wallet) {
    const stake = await StakeNFT(token, wallet);
    if (stake) {
      setConfirm(true);
      setReceipt(stake["transactionHash"]);
      getNfts();
      getStakingInformation();
    }
  }

  async function runStakeAll() {
    const stake = await stakeAllNFT(unstakedNFTsArray, props.wallet);
    if (stake) {
      setConfirm(true);
      setReceipt(stake["transactionHash"]);
      getNfts();
      getStakingInformation();
    }
  }

  async function unstakeAll() {
    const unstake = await unstakeNFTs(tokensStakedData, props.wallet);
    if (unstake) {
      setConfirm(true);
      setReceipt(unstake["transactionHash"]);
      getNfts();
      getStakingInformation();
    }
  }

  async function unstakeSingle(id) {
    const unstake = await unstakeNFT(id, props.wallet);
    if (unstake) {
      setConfirm(true);
      setReceipt(unstake["transactionHash"]);
      getNfts();
      getStakingInformation();
    }
  }

  async function claimTokens() {
    const claim = await claimRewards(props.wallet);

    if (claim) {
      setConfirm(true);
      setReceipt(claim["transactionHash"]);
      getNfts();
      getStakingInformation();
    }
  }

  async function getNfts() {
    const data = await getWalletNFTs(props.wallet);

    if (data) {
      let nftsArray = [];
      let tokensArray = [];

      for (let i = 0; i < data.result.length; i++) {
        const token_uri = data.result[i].token_uri;
        const token_id = data.result[i].token_id;
        try {
          const token_uri_spliced = "https://ipfs.io" + token_uri.slice(28);
          tokensArray.push(token_id);

          await axios
            .get(token_uri_spliced)
            .then((image_fetch) => {
              if (image_fetch.data.image) {
                let image =
                  "https://ipfs.io/ipfs/" + image_fetch.data.image.slice(7);

                console.log(image_fetch);

                const nftData = {
                  image: image,
                  attributes: image_fetch.attributes,
                  token_id: token_id,
                };

                nftsArray.push(nftData);
              } else return;
            })
            .catch((err) => console.log(err));
        } catch (err) {
          console.log(err);
        }
      }

      const nftTokensDataUnstaked = await Promise.all(tokensArray);
      const nftDataArray = await Promise.all(nftsArray);
      setNfts(nftDataArray);
      setUnstakedNFTsArray(nftTokensDataUnstaked);
    }
  }

  async function getStakingInformation() {
    const stakingRewards = await getStakingRewards(props.wallet);

    let tokensStakedArray = [];

    if (stakingRewards?._tokensStaked) {
      for (let i = 0; i < stakingRewards._tokensStaked.length; i++) {
        const number = Number(stakingRewards._tokensStaked[i]._hex);
        tokensStakedArray.push(number);
      }

      setStakingRewards(
        Number(stakingRewards._rewards._hex) / Math.pow(10, 18)
      );

      setTokenStaked(stakingRewards._tokensStaked.length);

      const tokensList = await Promise.all(tokensStakedArray);
      setTokensStakedData(tokensList);
    }
  }

  async function getTotalStakedNFTs() {
    const totalStaked = await getTotalStaked();
    setTotalStaked(totalStaked);
  }

  useEffect(() => {
    if (props.wallet === "") return navigate('/');
    getNfts();
    getStakingInformation();
    getTotalStakedNFTs()
  }, []);

  return (
    <div>
      <Description />
      <Balances />
      <ShowNFTs />
      <Receipt />
    </div >
  );

  function Description() {
    return (
      <>
        <div
          style={{ background: "transparent", border: "transparent" }}
          className="staking_div"
        >
          <div>
            <div className="marketplace_title">Cybros Staking</div>

            <div style={{ padding: ".5rem" }}>
              <div
                style={{
                  fontStyle: "Bebas Neue",
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  textAlign: "center"
                }}
              >
                {totalStaked} CYBROS are currently collecting {(totalStaked * 3) * 2} CYBRVERSE everyday.
              </div>
              <div
                style={{
                  fontStyle: "Bebas Neue",
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  textAlign: "center"
                }}
              >
                That's {numeral((totalStaked * 3) * 60).format('0,0')} every 30 days.
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }

  function Balances() {

    return (
      <div className="Balance_Div_Top">
        <div className="Balance_Div">
          <div style={{ fontSize: "1.25rem" }} className="staking_title">
            <div className="title_big">Staked CYBROS:</div>

            <div style={{ marginLeft: "10px" }}>
              <div className="title_big">{tokensStaked}</div>
            </div>
          </div>

          <button
            disabled={tokensStaked === 0 ? true : false}
            onClick={() => unstakeAll()}
            className="stake_button"
          >
            Unstake All ({tokensStaked})
          </button>
        </div>

        <div className="Balance_Div">
          <div className="staking_title">
            <div className="title_big">Unstaked CYBROS:</div>

            <div style={{ marginLeft: "10px" }}>
              <div className="title_big">
                {nfts.length}
                {/* <button onClick={() => getNfts()}>Refresh</button> */}
              </div>
            </div>
          </div>

          <button
            onClick={() => runStakeAll()}
            className="stake_button"
          >
            Stake All ({nfts.length})
          </button>
        </div>

        <div className="Balance_Div">
          <div className="staking_title">
            <div className="title_big">cybrverse/day:</div>

            <div style={{ marginLeft: "10px" }}>
              <div className="title_big">
                {(tokensStaked * 3) * 2}
              </div>
            </div>
          </div>
        </div>

        <div className="Balance_Div">
          <div className="staking_title">
            <div className="title_big">Claimable:</div>

            <div style={{ marginLeft: "10px" }}>
              <div className="title_big">
                {stakingRewards.toFixed(4)}
              </div>
            </div>
          </div>
          <button onClick={() => claimTokens()} className="stake_button">Claim</button>
        </div>
      </div>
    )
  }

  function ShowNFTs() {
    return (
      <>
        <div>
          <div className="staking_div">
            <div className="staking_map">
              {tokensStakedData.length === 0 && (
                <div className="title_big">You have not staked any CYBROS</div>
              )}

              {tokensStakedData &&
                tokensStakedData.map((tokens) => (
                  <div className="staking_card">
                    <div style={{ padding: "1rem" }}>
                      <div style={{ padding: "1rem", background: "#333" }}>
                        <div className="title">Staked Cybro</div>
                      </div>

                      <div style={{ padding: "1rem" }}>
                        <div>Token:</div>
                        <div className="title">Cybro #{tokens}</div>

                        <div className="title">Earning: $CYBR</div>
                      </div>

                      <div style={{ padding: "1rem" }}>
                        <button
                          onClick={() => unstakeSingle([tokens])}
                          style={{
                            width: "100%",
                            background: "#333",
                            padding: ".5rem",
                            color: "white",
                            fontFamily: "Bebas Neue, sans-serif",
                            fontSize: "1.2rem",
                            border: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          Unstake
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {nfts.length === 0 && tokensStaked === 0 && (
            <div
              style={{
                minHeight: "50dvh",
                textAlign: "center",
                fontSize: "2.5rem",
                padding: "1rem",
              }}
              className="staking_div"
            >
              No Cybro's found, you can check out the collection or mint one.
              <div className="cybros_mint_card">
                <div>Mint a CYBRO below!</div>
                <img
                  alt=""
                  className="cybro_mint_image"
                  src={
                    "https://i.seadn.io/gcs/files/2f88a6cbed3b571277bad6c6af8a56ea.png?auto=format&dpr=1&w=256"
                  }
                ></img>
                <button
                  onClick={() =>
                    window.open(
                      "https://bafybeidpp4d3znpjxlvlhitaylbqwmyk2gzcxazp5wkftvwu7r3lsyku2q.gateway.ipfscdn.io/?contract=0x9249A52Bb6D518b34Dcd0eAea31cCd66A4Fa6166&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fpolygon.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%7D&primaryColor=purple"
                    )
                  }
                  style={{
                    maxWidth: 500,
                    marginTop: "1.2rem",
                    borderRadius: "1.5rem",
                  }}
                  className="stake_button"
                >
                  MINT
                </button>
              </div>
            </div>
          )}

          <div className="staking_div">
            <div>
              <div className="staking_map">
                {nfts.length === 0 && tokensStaked > 0 && (
                  <div className="title_big">Your CYBROS are staked.</div>
                )}

                {nfts.length === 0 && tokensStaked === 0 && (
                  <div className="title_big">Your CYBROS are staked.</div>
                )}

                {nfts &&
                  nfts.map((nftsData) => (
                    <div className="staking_card">
                      <img
                        className="staking_nft_image"
                        alt=""
                        src={nftsData.image}
                      ></img>

                      <div
                        style={{
                          fontSize: "1.5rem",
                          padding: ".5rem",
                          marginTop: "auto",
                        }}
                      >
                        <div style={{ padding: ".5rem" }}>
                          <div>Token</div>
                          <div>CYBRO #{nftsData.token_id}</div>
                        </div>

                        <div>
                          <button
                            onClick={() =>
                              runStake(nftsData.token_id, props.wallet)
                            }
                            style={{
                              width: "100%",
                              background: "#333",
                              padding: ".5rem",
                              color: "white",
                              fontFamily: "Bebas Neue, sans-serif",
                              fontSize: "1.2rem",
                              border: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            Stake
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div style={{ padding: 10 }}></div>
        </div>
      </>
    );
  }

  function Receipt() {
    return (
      <>
        {confirm === true && (
          <div
            style={{
              display: "grid",
              padding: 10,
              background: "#333",
              color: "white",
              position: "fixed",
              // height: 100,
              // width: 100,
              right: 0,
              top: 0,
              margin: 10,
              zIndex: 2000000000,
              textAlign: "center",
              fontSize: "1.5rem",
              boxShadow: "0px 0px 10rem black",
            }}
          >
            <div>Transaction Confirmed!</div>
            {receipt.slice(0, 10)}...
            <div>
              <button
                onClick={() =>
                  window.open(`https://polygonscan.com/tx/${receipt}`)
                }
                style={{ fontSize: 16 }}
                className="stake_button"
              >
                View Transaction
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
