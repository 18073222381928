import { initializeApp } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs, addDoc, setDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBWGRACUiOaCeQU4gnrUii8e6he5JNCJ-w",
  authDomain: "cozy-site-65c2f.firebaseapp.com",
  databaseURL: "https://cozy-site-65c2f-default-rtdb.firebaseio.com",
  projectId: "cozy-site-65c2f",
  storageBucket: "cozy-site-65c2f.appspot.com",
  messagingSenderId: "99856821405",
  appId: "1:99856821405:web:d2cb1a821bdceeb9c87874"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export async function getUser(address) {
  console.log("Searching for address")
  console.log(address)

  const docRef = doc(db, "users", `${address}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    try {
      const docRef = await setDoc(doc(db, "users", address), {
        address: address,
        username: "",
        profileImage: "",
        points: 0
      });
      return docRef.data();
      // console.log("Document written with ID: ", docRef.id);
    } catch (err) {
      console.log(err)
    }
  }
}

export async function updateUsername(address, name) {
  const userDocument = doc(db, "users", `${address}`);
  await updateDoc(userDocument, {
    "username": name
  });
}