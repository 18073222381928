import "../styles/home.css";

import React, { useRef, useEffect } from "react";

import { addTokenToWallet, getContractNFTs } from "../server/Wallet";

import background_video from "../assets/background_video.mkv";
import join_the_discord from "../assets/join_the_discord.svg";
import twitter_logo from "../assets/social_logos/twitter_logo.svg";
import discord_logo from "../assets/social_logos/discord_logo.svg";
import linked_in from "../assets/social_logos/linkedIn_logo.svg";
// import polygon_coin from "../assets/polygon_coin.svg";
import symbol_1 from "../assets/symbol_1.svg";
import symbol_2 from "../assets/symbol_2.svg";
import symbol_3 from "../assets/symbol_3.svg";
import symbol_4 from "../assets/symbol_4.svg";

export default function Home() {
  const discord_ref = useRef();

  async function getCybros() {
    const data = await getContractNFTs()

    console.log(data)
  }

  useEffect(() => {
    getCybros()
  }, [])

  return (
    <div>
      <HomeScreen />

      <Contracts />

      <div className="video_div">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          src={background_video}
          className="background_video"
        ></video>
      </div>

      <ImageSlider />

      <MintACybro />

      <AboutSection />

      <DiscordSection />

      <Founder />
    </div>
  );

  function ImageSlider() {

    return (
      <div className="image_slider">
        <div className="image_track">
          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/77b6cecfdee51c8fa014ada31e03d674.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/f63a1be1bcc743c402b0da60ed67df09.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/8d589adbb3707851ce49b4c18a6cca83.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/943cc9dbfaf224f44c24bd0d99cf5506.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/3b5d39d24a8d1423845a8e24be6b0512.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/81efb21e03b652a3a683849e1ca5c1cd.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/8c91ec22f641835c7b97d6bd6ccc50fb.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/1ae90950a412b74bf3bbc91374cf8e77.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/bc53d8b82453f13025659cc27ab99672.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/c29a771a7340f9c7085b9ebfe6207ccb.png"
              }
            ></img>
          </div>
        </div>

        <div className="image_track">
          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/77b6cecfdee51c8fa014ada31e03d674.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/f63a1be1bcc743c402b0da60ed67df09.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/8d589adbb3707851ce49b4c18a6cca83.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/943cc9dbfaf224f44c24bd0d99cf5506.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/3b5d39d24a8d1423845a8e24be6b0512.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/81efb21e03b652a3a683849e1ca5c1cd.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/8c91ec22f641835c7b97d6bd6ccc50fb.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/1ae90950a412b74bf3bbc91374cf8e77.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/bc53d8b82453f13025659cc27ab99672.png"
              }
            ></img>
          </div>

          <div className="ape_image">
            <img
              height={350}
              src={
                "https://dl.openseauserdata.com/cache/originImage/files/c29a771a7340f9c7085b9ebfe6207ccb.png"
              }
            ></img>
          </div>
        </div>
      </div>
    )
  }

  function MintACybro() {

    return (
      <div className="Mint_A_Cybro">
        <div className="Cybro_Mint_Title">Mint a Cybro</div>

        <div className="Cybro_Mint_Section">
          <div
            className="Mint_Cybro"
          >
            <div style={{ width: "100%", textAlign: "center", fontSize: "2rem" }}>
              <div className="Rewards">
                Staking Rewards
              </div>

              <div className="Rewards">
                Private Discord Tools
              </div>

              <div className="Rewards">
                Private Marketplace Usage
              </div>
            </div>

            <iframe
              src={"https://embed.ipfscdn.io/ipfs/bafybeihazpt6pkm4azgtupdz7hc2j3o4zpjsvtcgfq4t2keozxkss3ud6q/?contract=0x9249A52Bb6D518b34Dcd0eAea31cCd66A4Fa6166&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fpolygon.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fpolygon%2F512.png%22%2C%22height%22%3A512%2C%22width%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=c99ec263696521665186e3c46290950b&primaryColor=purple"}
              style={{
                height: "600px",
                borderRadius: "2rem",
                border: "transparent",
                marginLeft: "auto",
                width: "100%",
                padding: ".5rem",
                overflow: "auto"
              }}
            >

            </iframe>
          </div>
        </div>
      </div>
    )
  }

  function Contracts() {
    return (
      <div className="links_div">
        <div className="links">
          <button
            onClick={() =>
              window.open(
                "https://polygonscan.com/address/0x89c1878366c9a12cc232894675a68704b183a372"
              )
            }
            style={{ fontSize: "1rem" }}
            className="connect_button"
          >
            Staking Contract
          </button>
          <button
            onClick={() =>
              window.open(
                "https://polygonscan.com/address/0x2B70F324b5d7cB01BC7bc2551BeCD3bb5948a7d2"
              )
            }
            style={{ fontSize: "1rem" }}
            className="connect_button"
          >
            $CYBERVERSE Contract
          </button>
          <button
            onClick={() =>
              window.open("https://opensea.io/collection/cybronft")
            }
            style={{ fontSize: "1rem" }}
            className="connect_button"
          >
            CYBROS Opensea
          </button>
        </div>
      </div>
    );
  }

  function HomeScreen() {
    return (
      <div className="home_title">
        <div>My Cozy Universe</div>
        <div
          style={{
            fontSize: "1.3rem",
            opacity: "100%",
            marginTop: "1.2rem",
          }}
        >
          CYBROS / Cozy Place / Opepen Islands
        </div>

        <div className="social_div_home">
          <button
            title="Twitter"
            onClick={() => window.open("https://twitter.com/cybrosnft")}
            className="social_button_home"
          >
            <img alt="" src={twitter_logo}></img>
          </button>

          <button
            title="Discord"
            onClick={() =>
              discord_ref.current.scrollIntoView({ behavior: "smooth" })
            }
            className="social_button_home"
          >
            <img alt="" src={discord_logo}></img>
          </button>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "grid",
            maxWidth: 250,
          }}
        >
          <button className="connect_button" onClick={() => addTokenToWallet()}>
            Add $CYBERVERSE to wallet
          </button>
        </div>

        <img alt="" className="rocket_launch" src={symbol_1}></img>
      </div>
    );
  }

  function AboutSection() {
    return (
      <div className="about_the_creator">
        <div className="founder_description">
          <div>
            <div>
              <div style={{ padding: "1.2rem" }}>
                <div style={{ textAlign: "left" }} className="title_big">
                  Affordable NFTs
                </div>
                <div
                  style={{
                    padding: "1.2rem",
                    fontSize: "2rem",
                    lineHeight: "2rem",
                    textAlign: "left",
                  }}
                  className="title"
                >
                  Cybros started as a Free Mint and then changed to 1 Matic
                  after a certain time period passed.
                </div>

                <div
                  style={{
                    padding: "1.2rem",
                    fontSize: "2rem",
                    lineHeight: "2rem",
                    textAlign: "left",
                  }}
                  className="title"
                >
                  Since our Marketplace & Staking have been in development our initial mint cost has been updated to 5 Matic each Cybro.
                </div>
              </div>

              <img alt="" className="rocket_launch" src={symbol_4}></img>

              <div style={{ padding: "1.2rem" }}>
                <div style={{ textAlign: "left" }} className="title_big">
                  Rewards for holders
                </div>
                <div
                  style={{
                    padding: "1.2rem",
                    fontSize: "2rem",
                    lineHeight: "2rem",
                    textAlign: "left",
                  }}
                  className="title"
                >
                  Cybros holder will have voting rights and access to the
                  marketplace raffles and NFT's that you can buy with
                  $CYBRVERSE.
                </div>
              </div>

              <img alt="" className="bar_3d" src={symbol_3}></img>

              <div style={{ padding: "1.2rem" }}>
                <div style={{ textAlign: "left" }} className="title_big">
                  Community-driven project
                </div>
                <div
                  style={{
                    padding: "1.2rem",
                    fontSize: "2rem",
                    lineHeight: "2rem",
                    textAlign: "left",
                  }}
                  className="title"
                >
                  CYBROS is a community-driven project, so holders will have a
                  say in the future of the project.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DiscordSection() {
    return (
      <div ref={discord_ref} style={{ width: "100%" }}>
        <div className="discord_background">
          <img alt="" className="bar_3d" src={symbol_2}></img>

          <div className="founder_description">
            <div className="discord_modal">
              <img
                alt=""
                style={{ width: "100%" }}
                src={join_the_discord}
              ></img>
              <button
                onClick={() => window.open("https://discord.gg/ZsGKJRMf7f")}
                style={{
                  maxWidth: 250,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="stake_button"
              >
                Join The Discord
              </button>
            </div>
          </div>

          <img alt="" className="rocket_launch" src={symbol_3}></img>
        </div>
      </div>
    );
  }

  function Founder() {
    return (
      <div className="founder_background">
        <img alt="" className="bar_3d" src={symbol_2}></img>

        <div style={{ padding: 29 }} className="founder_description">
          <div>
            <img
              className="creator_image"
              style={{
                border: "1px white solid",
                boxShadow: "8px 8px 10px black",
              }}
              alt=""
              src={"https://avatars.githubusercontent.com/u/25094504?v=4"}
            ></img>

            <div className="social_div_home">
              <button
                title="Twitter"
                onClick={() => window.open("https://twitter.com/DevGwardoNFT")}
                className="social_button_home"
              >
                <img alt="" src={twitter_logo}></img>
              </button>

              <button
                title="Discord"
                onClick={() =>
                  discord_ref.current.scrollIntoView({ behavior: "smooth" })
                }
                className="social_button_home"
              >
                <img alt="" src={discord_logo}></img>
              </button>

              <button
                title="LinkedIn"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/torreykimbrough/")
                }
                className="social_button_home"
              >
                <img alt="" src={linked_in}></img>
              </button>
            </div>
          </div>
          <div style={{ textAlign: "left" }} className="title_big">
            About the creator
          </div>
          <div style={{ textAlign: "left" }}>
            Gwardo420, a visionary founder, created multiple collections of free
            NFTs, leveraging blockchain technology to empower users.
            <div style={{ padding: "1.5rem" }}></div>
            These NFTs provide access to passive income and rewards,
            democratizing the digital economy and fostering inclusivity in the
            web3 world.
          </div>
        </div>
      </div>
    );
  }
}
