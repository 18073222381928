// import Moralis from 'moralis';

import axios from 'axios';
import { ethers } from 'ethers';
// import dotenv from 'dotenv';

const marketplaceABI = require('./marketplace.json');
const cyberVerseABI = require('./cyberVerse.json');
const cybrosABI = require('./cybrosABI.json');

const STAKING_CONTRACT = "0x89c1878366c9a12Cc232894675a68704B183A372";
const CYBROS_NFT = "0x9249A52Bb6D518b34Dcd0eAea31cCd66A4Fa6166";

// Test Net
const MARKETPLACE_CONTRACT = "0xD128Bb1c5a2741EcC4F290Dd83776477705E81C3";
const CyberVerseToken = "0x2B70F324b5d7cB01BC7bc2551BeCD3bb5948a7d2";

export async function getContractNFTs() {
  console.log("Checking Contract NFTs")
}

export async function getTotalStaked() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const CyberVerse = new ethers.Contract(CYBROS_NFT, cybrosABI, provider);
    const balance = await CyberVerse.balanceOf(STAKING_CONTRACT);

    console.log(Number(balance))
    return Number(balance);
  } catch (err) {
    console.log(err)
  }
}

// Conenct Wallet
export async function checkConnected() { // Checking if wallet is connected
  return window.ethereum.request({ method: 'eth_accounts' }).then((data) => {
    // console.log(data)
    return data;
  });
}

export async function ConnectWallet() { // Connect user wallet when initiated
  if (window.ethereum) {
    try {
      if (window.ethereum.chainId !== "0x89") {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("wallet_switchEthereumChain", [{ chainId: '0x89' }])
          .then(async (wallet) => {
            return wallet[0];
          })


        let wallet = await provider.send("eth_requestAccounts", [])
          .then(async (wallet) => {
            return wallet[0];
          })

        return wallet;
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        console.log(provider)

        let wallet = await provider.send("eth_requestAccounts", [])
          .then(async (wallet) => {
            return wallet[0];
          })

        return wallet;
      }
    } catch (err) {
      console.log(err)
    }
  } else alert("Open window in wallet")
}

export async function addTokenToWallet() { // Add CyberVerse to wallet
  try {
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: "0x2B70F324b5d7cB01BC7bc2551BeCD3bb5948a7d2", // The address that the token is at.
          symbol: "CybrVerse", // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          image: "https://bafybeigf33zcgibei2pbqhuozlhmayi62pnbpbmdklky5knvn4clh7o6hy.gateway.ipfscdn.io/Cybros_Token.webp", // A string url of the token logo
        },
      },
    });
    console.log(wasAdded);
  } catch (err) {
    console.log(err)
  }
}

let userNFTs = [];

// Get Wallet NFTs
export async function getWalletNFTs(address) { // Gets the user's NFT's from wallet (Cybros)
  try {
    const stakingContract = STAKING_CONTRACT;
    const abiFunction = [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_staker",
          "type": "address"
        }
      ],
      "name": "getStakeInfo",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokensStaked",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256",
          "name": "_rewards",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const testStakingContract = new ethers.Contract(stakingContract, abiFunction, provider);
    const balance = await testStakingContract.getStakeInfo(`${address}`);
    console.log(balance._tokensStaked.length)
    const tokensStaked = balance._tokensStaked.length;
    if (tokensStaked > 0) return;
    const options = {
      method: 'GET',
      url: `https://deep-index.moralis.io/api/v2/${address}/nft?chain=POLYGON&format=decimal&token_addresses%5B0%5D=${CYBROS_NFT}&normalizeMetadata=true`,
      headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_KEY }
    };

    const userWalletNFTs = await axios.request(options).then((nfts) => { return nfts.data });

    console.log(userWalletNFTs);
    userNFTs.push(userWalletNFTs);
    return userWalletNFTs;
  } catch (e) {
    console.error(e);
  }
}

// Staking Rewards Balance
export async function getStakingRewards() { // Gets the users staking rewards 
  const wallet = await checkConnected();
  try {
    const stakingContract = STAKING_CONTRACT;
    const abiFunction = [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_staker",
          "type": "address"
        }
      ],
      "name": "getStakeInfo",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokensStaked",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256",
          "name": "_rewards",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const testStakingContract = new ethers.Contract(stakingContract, abiFunction, provider);
    const balance = await testStakingContract.getStakeInfo(`${wallet[0].toString()}`);
    return balance;
  } catch (err) {
    console.log(err)
  }
}

// Claim Staking Rewards Balance
export async function claimRewards() { // Claims the users rewards
  try {
    const stakingContract = STAKING_CONTRACT;
    const abiFunction = [{
      "inputs": [],
      "name": "claimRewards",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const testStakingContract = new ethers.Contract(stakingContract, abiFunction, signer);

    const contractGas = await testStakingContract.estimateGas.claimRewards();

    const transaction = await testStakingContract.claimRewards({ gasLimit: contractGas })
    alert("Transaction is pending!")
    const receipt = transaction.wait()
    console.log(receipt)
    return receipt;
  } catch (err) {
    console.log(err)
  }
}

// Stake an NFT
export async function StakeNFT(id) { // Stakes an NFT (Cybros)
  const wallet = await checkConnected();

  try {
    const stakingContract = STAKING_CONTRACT;
    const stakingABI = [{
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },];

    await checkApproved(wallet[0])

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const testStakingContract = new ethers.Contract(stakingContract, stakingABI, signer);

    const contractGas = await testStakingContract.estimateGas.stake([id.toString()]);

    const transaction = await testStakingContract.stake([id.toString()], { gasLimit: contractGas })
    alert("Transaction is pending!")
    const receipt = await transaction.wait()
    console.log(receipt)
    return receipt;
  } catch (err) {
    console.log(err)
  }
}

// Stake an NFT
export async function stakeAllNFT(nfts) { // Stake all Cybros from user
  const wallet = await checkConnected();
  try {
    const stakingContract = STAKING_CONTRACT;
    const stakingABI = [{
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },];

    await checkApproved(wallet[0])

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const testStakingContract = new ethers.Contract(stakingContract, stakingABI, signer);

    const contractGas = await testStakingContract.estimateGas.stake(nfts)

    const transaction = await testStakingContract.stake(nfts, { gasLimit: contractGas })
    alert("Transaction is pending!")
    const receipt = transaction.wait()
    console.log(receipt)
    return receipt;
  } catch (err) {
    console.log(err)
  }
}

// Check NFT Staking Approval
async function checkApproved(wallet) { // Check if Cybros are approved
  try {
    const isApprovedABI = [
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          }
        ],
        "name": "isApprovedForAll",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
    ];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const cybrosContract = new ethers.Contract(CYBROS_NFT, isApprovedABI, provider);
    await cybrosContract.isApprovedForAll(wallet, STAKING_CONTRACT).then(async (approved) => {

      if (approved === true) return;

      const approvalABI = [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "setApprovalForAll",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
      ]
      const signer = provider.getSigner()
      const cybrosContract = new ethers.Contract(CYBROS_NFT, approvalABI, signer);
      const transaction = await cybrosContract.setApprovalForAll(STAKING_CONTRACT, true)
      console.log(transaction)
      return transaction;
    })
  } catch (err) {
    console.log(err)
  }
}

// Unstake NFTs
export async function unstakeNFTs(array) { // Unstakes a users NFT's
  try {
    const stakingContract = STAKING_CONTRACT;
    const stakingABI = [{
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },];

    // await checkApproved(stakingContract, wallet)

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const testStakingContract = new ethers.Contract(stakingContract, stakingABI, signer);

    const contractGas = await testStakingContract.estimateGas.withdraw(array);

    const transaction = await testStakingContract.withdraw(array, { gasLimit: contractGas })
    alert("Transaction is pending!")
    const receipt = transaction.wait()
    console.log(receipt)
    return receipt;
  } catch (err) {

  }
}

// Unstake NFTs
export async function unstakeNFT(id) { // Unstakes a certain NFT 
  try {
    const stakingContract = STAKING_CONTRACT;
    const stakingABI = [{
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },];

    // await checkApproved(stakingContract, wallet)

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const testStakingContract = new ethers.Contract(stakingContract, stakingABI, signer);

    const contractGas = await testStakingContract.estimateGas.withdraw(id);

    const transaction = await testStakingContract.withdraw(id, { gasLimit: contractGas })
    alert("Transaction is pending!")
    const receipt = await transaction.wait()
    console.log(receipt)
    return receipt;
  } catch (err) {
    console.log(err)
  }
}

const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Market Data
export async function getMarketNFTs() {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const marketplace = new ethers.Contract(MARKETPLACE_CONTRACT, marketplaceABI, signer);
    const liveNFTs = await marketplace.fetchMarketItems();

    let marketplaceNFTs = [];

    for (let i = 0; i < liveNFTs.length; i++) {
      const contractAddress = liveNFTs[i].nftContract;
      const tokenID = Number(liveNFTs[i].tokenId);
      const price = Number(liveNFTs[i].price);
      const seller = liveNFTs[i].seller;
      const itemId = Number(liveNFTs[i].itemId);

      const options = { method: 'GET', headers: { accept: 'application/json', 'X-API-KEY': 'fef65160cb7f498f903de2e57a82ea71' } };
      await fetch(`https://api.opensea.io/v2/chain/matic/contract/${contractAddress}/nfts/${tokenID}`, options)
        .then(response => response.json())
        .then(async response => {
          await timeout(1000);
          console.log(response['nft'])
          let options = {
            nftData: response['nft'],
            price: price,
            seller: seller,
            itemId: itemId
          }
          marketplaceNFTs.push(options)
        })
        .catch((err) => console.log(err))
    }

    const nftData = await Promise.all(marketplaceNFTs)
    return nftData;
  } else {
    return alert("Open this in a wallet browser.")
  }
}

export async function getMyMarketNFTs() {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const marketplace = new ethers.Contract(MARKETPLACE_CONTRACT, marketplaceABI, signer);
    const liveNFTs = await marketplace.fetchMyNFTs();

    let marketplaceNFTs = [];

    for (let i = 0; i < liveNFTs.length; i++) {
      const contractAddress = liveNFTs[i].nftContract;
      const tokenID = Number(liveNFTs[i].tokenId);
      const price = Number(liveNFTs[i].price);
      const seller = liveNFTs[i].seller;
      const itemId = Number(liveNFTs[i].itemId);

      const options = { method: 'GET', headers: { accept: 'application/json', 'X-API-KEY': 'fef65160cb7f498f903de2e57a82ea71' } };
      await fetch(`https://api.opensea.io/v2/chain/matic/contract/${contractAddress}/nfts/${tokenID}`, options)
        .then(response => response.json())
        .then(async response => {
          await timeout(1000);
          console.log(response['nft'])
          let options = {
            nftData: response['nft'],
            price: price,
            seller: seller,
            itemId: itemId
          }
          marketplaceNFTs.push(options)
        })
        .catch((err) => console.log(err))
    }

    const nftData = await Promise.all(marketplaceNFTs)
    return nftData;
  } else {
    return alert("Open this in a wallet browser.")
  }
}

export async function getBuyMarketNFT(address, tokenId, price) {
  try {
    const wallet = await checkConnected();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const marketplace = new ethers.Contract(MARKETPLACE_CONTRACT, marketplaceABI, signer);
    const cybrosContract = new ethers.Contract(CyberVerseToken, cyberVerseABI, signer);
    const allowance = await cybrosContract.allowance(wallet[0], MARKETPLACE_CONTRACT);

    const checkedAllowance = Number(allowance) / Math.pow(10, 18);
    const itemPrice = ethers.utils.parseUnits(String(price), "ether")

    if (checkedAllowance < Number(itemPrice / Math.pow(10, 18))) {
      const cybrosContract = new ethers.Contract(CyberVerseToken, cyberVerseABI, signer);
      const approveWait = await cybrosContract.approve(MARKETPLACE_CONTRACT, itemPrice);

      return await approveWait.wait().then(async () => {
        const contractGas = await marketplace.estimateGas.createMarketSale(address, tokenId);
        const liveNFTs = await marketplace.createMarketSale(address, tokenId, { gasLimit: contractGas });
        const receipt = await liveNFTs.wait()
        return receipt;
      })
    } else {
      console.log("Allowance was correct!")
      const contractGas = await marketplace.estimateGas.createMarketSale(address, tokenId);
      const liveNFTs = await marketplace.createMarketSale(address, tokenId, { gasLimit: contractGas });
      const receipt = await liveNFTs.wait()
      return receipt;
    }
  } catch (err) {
    console.log(err.reason)
    if (err.reason === "execution reverted: ERC20: insufficient allowance") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const cybrosContract = new ethers.Contract(CyberVerseToken, cyberVerseABI, signer);
      await cybrosContract.approve(MARKETPLACE_CONTRACT, ethers.utils.parseUnits(String(price), "ether"));
    } else if (err.reason === "user rejected transaction") {
      return "User Rejected Transaction";
    } else if (err.reason) {
      return err.reason;
    }
    return err.reason;
  }
}

export async function getUserBalance(address) {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const CyberVerse = new ethers.Contract(CyberVerseToken, cyberVerseABI, provider);
  const balance = await CyberVerse.balanceOf(address.toString());

  return Number(balance);
}

export async function getUserTransactions() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const block = await provider.getBlock()

  console.log(block)

  const wallet = await checkConnected();
  return await axios.get(`https://api.polygonscan.com/api?module=account&action=txlist&address=${wallet[0]}&startblock=0&endblock=${block.number}&page=1&offset=10&sort=desc&apikey=${process.env.REACT_APP_BART_API_KEY}`).then((data) => {
    console.log(data)
    return data;
  });
}